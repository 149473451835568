import { css } from '@emotion/react';
import styled from '@emotion/styled';
import '@ui-v2/theme/theme';
import {
  ThemeBackgroundColourProps,
  ThemeBorderProps,
  ThemeDisplayProp,
  ThemeFlexboxProps,
  ThemeLayoutProps,
  ThemeMarginProps,
  ThemeOpacityProps,
  ThemeOverflowProps,
  ThemePaddingProps,
  ThemePositionProps,
  ThemeTextColourProps,
  ThemeTextProps,
  ThemeVisibilityProps,
} from '@ui-v2/types/props';
import { buildResponsiveValues } from '@ui-v2/utils/buildResponsiveValues';
import {
  buildBackgroundColour,
  buildBorderProps,
  buildColour,
  buildDisplay,
  buildFlexProps,
  buildLayoutProps,
  buildMargin,
  buildOpacityProps,
  buildOverflowProps,
  buildPadding,
  buildPositionProps,
  buildTextProps,
  buildVisibilityProps,
} from '@ui-v2/utils/themePropBuilders';

export type BoxProps = Omit<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>,
  'ref'
> &
  ThemeMarginProps &
  ThemePaddingProps &
  ThemeFlexboxProps &
  ThemePositionProps &
  ThemeLayoutProps &
  ThemeBorderProps &
  ThemeOverflowProps &
  ThemeTextProps &
  ThemeTextColourProps &
  ThemeBackgroundColourProps &
  ThemeOpacityProps &
  ThemeVisibilityProps & {
    as?: 'div' | 'span' | 'button';
    children?: React.ReactNode;
    display?: ThemeDisplayProp;
  };

const StyledBox = styled.div<BoxProps>(({ theme, ...props }) => [
  css(
    buildResponsiveValues({
      ...buildDisplay(props),
      ...buildFlexProps(props),
      ...buildColour(props, theme),
      ...buildMargin(props),
      ...buildPadding(props),
      ...buildPositionProps(props, theme),
      ...buildLayoutProps(props, theme),
      ...buildBorderProps(props, theme),
      ...buildOverflowProps(props),
      ...buildTextProps(props),
      ...buildBackgroundColour(props, theme),
      ...buildOpacityProps(props),
      ...buildVisibilityProps(props),
    }),
  ),
]);

const Box = (props: BoxProps) => <StyledBox {...props} />;

export default Box;
