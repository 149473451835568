import { css } from '@emotion/react';
import styled from '@emotion/styled';
import '@ui-v2/theme/theme';
import {
  ThemeFontWeightProps,
  ThemeMarginProps,
  ThemeOverflowProps,
  ThemePaddingProps,
  ThemeTextColourProps,
} from '@ui-v2/types/props';
import { TextVariant } from '@ui-v2/types/typography';
import { buildResponsiveValues } from '@ui-v2/utils/buildResponsiveValues';
import {
  buildColour,
  buildFontWeightProps,
  buildMargin,
  buildPadding,
  buildOverflowProps,
} from '@ui-v2/utils/themePropBuilders';
import { buildTextVariant } from './variants';

export type TextProps = Omit<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>,
  'ref' | 'color'
> &
  ThemeMarginProps &
  ThemePaddingProps &
  ThemeTextColourProps &
  ThemeOverflowProps &
  ThemeFontWeightProps & {
    as:
      | 'h1'
      | 'h2'
      | 'h3'
      | 'h4'
      | 'h5'
      | 'h6'
      | 'p'
      | 'div'
      | 'span'
      | 'label';
    children?: React.ReactNode;
    dataCy?: string;
    htmlFor?: string;
    variant?: TextVariant;
  };

const variantsWithTextBalancing: TextVariant[] = [
  'heading-1',
  'heading-2',
  'heading-3',
  'display-1',
];

const StyledText = styled('div')<TextProps>(
  ({ textWrap, theme, variant = 'body-1', ...props }) => [
    // Non responsive values
    css`
      text-wrap: ${textWrap || variantsWithTextBalancing.includes(variant)
        ? 'balance'
        : undefined};
    `,
    // Responsive values
    css(
      buildResponsiveValues({
        ...buildTextVariant(variant, theme),
        ...buildColour(props, theme),
        ...buildMargin(props),
        ...buildPadding(props),
        ...buildOverflowProps(props),
      }),
    ),
    props.fontWeight &&
      css(
        buildResponsiveValues({
          ...buildFontWeightProps(props),
        }),
      ),
  ],
);

const Text = ({ as, dataCy, variant = 'body-1', ...props }: TextProps) => (
  <StyledText as={as} data-cy={dataCy} variant={variant} {...props} />
);

export default Text;
